<template>
  <div id="UserList">
    <title-nav :title="'세미나 참여현황'" :nav="'제휴 / 세미나 참여현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-input-group size="sm">
                <b-form-select
                  :options="options"
                  v-model="selectedEventCd"
                  @change="changeCd"
                  size="sm"
                ></b-form-select>
              </b-input-group>
            </b-col>
            <b-col>{{ records }}명</b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center table-head-fixed"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
            <template v-slot:cell(delete)="data">
              <b-button
                variant="secondary"
                size="sm"
                @click="showModal(data)"
                class="text-nowrap"
                >삭제</b-icon
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-container>
    <template>
      <b-modal
        ref="modal-delete"
        size="sm"
        hide-footer
        centered
        title="정말 삭제하시겠습니까?"
        button-size="sm"
      >
        <p>
          <b-row>
            <b-col>세미나 :</b-col>
            <b-col>{{ this.selected_item.EVENT_CD }}</b-col>
          </b-row>
          <b-row>
            <b-col>이름 :</b-col>
            <b-col>{{ this.selected_item.USER_NAME }}</b-col>
          </b-row>
          <b-row>
            <b-col>연락처 :</b-col>
            <b-col>{{ this.selected_item.HPNO }}</b-col>
          </b-row>
          <b-row>
            <b-col>Email :</b-col>
            <b-col>{{ this.selected_item.EMAIL }}</b-col>
          </b-row>
        </p>
        <b-row>
          <b-col>
            <b-button class="m-1" variant="danger" @click="removeUser()">삭제</b-button>
            <b-button class="m-1" @click="$refs['modal-delete'].hide()">닫기</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { seminar_group, seminar_list, seminar_delete } from '@/api/cs'

export default {
  name: 'EvestSeminar',
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      isBusy: false,
      options: [],
      selectedEventCd: '',
      fields: [
        {
          key: 'USER_NAME',
          label: '이름'
        },
        {
          key: 'HPNO',
          label: '연락처'
        },
        {
          key: 'EMAIL',
          label: 'EMAIL'
        },
        {
          key: 'INTERESTED',
          label: 'INTERESTED'
        },
        {
          key: 'QUESTION',
          label: '질문'
        },
        {
          key: 'ATTR1',
          label: '소속'
        },
        {
          key: 'ATTR2',
          label: '학번'
        },
        {
          key: 'ATTR3',
          label: '단과대학'
        },
        {
          key: 'ATTR4',
          label: '학과'
        },
        {
          key: 'ATTR4',
          label: '학과'
        },
        {
          key: 'UPD_DT',
          label: '등록일'
        },
        {
          key: 'delete',
          label: '삭제',
          colType: 'button',
          tdClass: 'w5'
        }
      ],
      items: [],
      striped: true,
      bordered: false,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      selected_item: {}
    }
  },
  methods: {
    async init() {
      this.isBusy = true

      const response = await seminar_group()
      if (response.code === '0000') {
        this.options = response.data
        const cnt = this.options.length
        this.selectedEventCd = this.options[cnt - 1]
        this.getList()
      } else {
        alert(response.message)
      }

      this.isBusy = false
    },
    async getList() {
      if (this.selectedEventCd && this.selectedEventCd !== '') {
        this.isBusy = true
        const response = await seminar_list(this.selectedEventCd)
        if (response.code === '0000') {
          this.items = response.data
          this.records = this.items.length
        } else {
          alert(response.message)
        }
        this.isBusy = false
      }
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    changeCd(val) {
      this.selectedEventCd = val
      this.getList()
    },
    async showModal(data) {
      this.selected_item = data.item
      this.$refs['modal-delete'].show()
    },
    async removeUser() {
      try {
        const resp = await seminar_delete(this.selectedEventCd, { user_name: this.selected_item.USER_NAME, hpno: this.selected_item.HPNO, email: this.selected_item.EMAIL })
        if (resp.code == '0000') {
          alert('삭제되었습니다.')
          this.$refs['modal-delete'].hide()
          this.selected_item = {}
          this.setPage(1)
        }
      } catch (error) {
        console.error(error)
      }
      //const resp = await getLectureInfo(item.lc_no)
      //console.log(resp.data)
    }
  },
  mounted() {
    this.init()
  }
}
</script>
